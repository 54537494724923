<template>
  <div v-if="description" class="page-background">
    <div class="content" v-html="description"></div>
  </div>
</template>

<script>
import store from '../store/index';
export default {
  name: 'background-text',
  props: ['data'],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, this.model.displaySecond * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundText;
    },
    description() {
      return `${this.model.description}`;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
.page-background {
  background-color: white;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    padding: 40px;
  }
}
</style>
